<template>
	<v-card @click="openEdit" class="mb-1" elevation="9">
		<v-list-item three-line>
			<v-list-item-avatar class="mt-auto mb-auto">
				<v-avatar>
					<template v-if="imageUrl.length">
						<v-img v-if="service.usedByCount > 0" :src="imageUrl" />
						<v-img id="darkImage" v-else :src="imageUrl" />
					</template>
					<template v-else>
						<v-icon>mdi-cogs</v-icon>
					</template>
				</v-avatar>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{ service.name }}</v-list-item-title>
				<v-list-item-subtitle>
					Används av {{ service.usedByCount }} företag
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon class="align-self-center">
				<v-icon>mdi-information-outline</v-icon>
			</v-list-item-icon>
		</v-list-item>
		<template v-if="edit">
			<a-service-form-modal
				:service="service"
				:edit.sync="edit"
				title="Ändra tjänst"
			/>
		</template>
	</v-card>
</template>

<script>
import axios from 'axios';
import AServiceFormModal from '@/components/ServiceFormModal';
export default {
	components: {
		AServiceFormModal,
	},
	props: {
		service: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		edit: false,
		imageUrl: '',
	}),
	watch: {
		'service.imageUrl'() {
			this.setImage();
		},
	},
	async created() {
		await this.setImage();
	},
	methods: {
		openEdit() {
			this.edit = true;
		},
		getImageId() {
			if (this.service.usedByCount > 0) {
				return '';
			}

			return 'darkImage';
		},
		async setImage() {
			// Verify that the image exist, return '' otherwise.
			try {
				const response = await axios.get(this.service.imageUrl);
				if (response.status === 200) {
					this.imageUrl = this.service.imageUrl;
				}
			} catch (error) {
				this.imageUrl = '';
			}
		},
	},
};
</script>

<style>
#darkImage {
	filter: brightness(40%);
}
</style>
