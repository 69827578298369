<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<template v-slot:activator="{ on }" v-if="!edit">
			<div class="d-flex mx-4 mt-4">
				<v-btn
					v-on="on"
					color="secondary"
					class="flex-grow-1 flex-sm-grow-0"
					dark
					>Skapa företag</v-btn
				>
			</div>
		</template>

		<v-app-bar dark fixed color="primary" class="app-bar">
			<v-btn @click="dialog = false" icon dark>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-toolbar-title>{{ title }}</v-toolbar-title>
			<v-spacer />
			<v-toolbar-items>
				<v-btn @click="resetForm(company)" dark text>Återställ</v-btn>

				<v-btn @click="submit" :disabled="!valid" dark text>Spara</v-btn>
			</v-toolbar-items>
		</v-app-bar>

		<v-container class="toolbar-spacing white">
			<v-form ref="form" v-model="valid">
				<v-card class="pa-4 ma-4">
					<v-subheader>Autentisering</v-subheader>

					<v-row>
						<v-col cols="12" md="3">
							<!--
								autocomplete set to an unexpected value to DISABLE it in Chrome.
								https://stackoverflow.com/questions/65116682/how-to-disable-autocomplete-with-v-form
							-->
							<v-text-field
								v-model="form.id"
								:error-messages="errorMessages('id')"
								@blur="$v.form.id.$touch()"
								:disabled="edit"
								prepend-icon="mdi-account"
								label="Client ID"
								hint="Företagets id i TimeWaves databas"
								type="text"
								autocomplete="clientid"
							/>
						</v-col>

						<v-col cols="12" md="9">
							<v-text-field
								v-model="form.secret"
								:disabled="edit"
								prepend-icon="mdi-lock"
								label="Client secret"
								hint="Företagets hemlighet till TimeWaves API"
								type="text"
								counter="255"
								autocomplete="secret"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Företagsinformation</v-subheader>

					<v-row>
						<v-col cols="12" md="8">
							<v-text-field
								v-model="form.name"
								:error-messages="errorMessages('name')"
								@blur="$v.form.name.$touch()"
								prepend-icon="mdi-tag"
								label="Företagsnamn"
								hint="Används till titlar och dylikt i appen"
								type="text"
								counter="100"
							/>
						</v-col>

						<v-col cols="12" md="4">
							<v-text-field
								v-model="form.orgnr"
								:error-messages="errorMessages('orgnr')"
								@blur="$v.form.orgnr.$touch()"
								prepend-icon="mdi-id-card"
								label="Organisationsnummer"
								type="text"
								required
								counter="11"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-textarea
								v-model="form.cancelRule"
								label="Avbokningsregler"
								auto-grow
								rows="2"
								counter="400"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Kontaktinformation</v-subheader>

					<v-row>
						<v-col cols="12" md="8">
							<v-text-field
								v-model="form.email"
								:error-messages="errorMessages('email')"
								@blur="$v.form.email.$touch()"
								prepend-icon="mdi-email"
								label="E-postadress"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								required
								counter="100"
							/>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field
								v-model="form.tel"
								:error-messages="errorMessages('tel')"
								@blur="$v.form.tel.$touch()"
								prepend-icon="mdi-phone"
								label="Telefonnummer"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								required
								counter="20"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Besöksinformation</v-subheader>

					<v-row>
						<v-col cols="12" md="12">
							<v-text-field
								v-model="form.streetAddress"
								prepend-icon="mdi-road"
								label="Gatuadress"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								required
								counter="100"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="12">
							<v-text-field
								v-model="form.coCity"
								prepend-icon="mdi-account-network"
								label="Eventuell C/O"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								counter="100"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="3">
							<v-text-field
								v-model="form.areaCode"
								:error-messages="errorMessages('areaCode')"
								@blur="$v.form.areaCode.$touch()"
								prepend-icon="mdi-file-find-outline"
								label="Postkod"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								required
								counter="6"
							/>
						</v-col>

						<v-col cols="12" md="9">
							<v-text-field
								v-model="form.city"
								:error-messages="errorMessages('city')"
								@blur="$v.form.city.$touch()"
								prepend-icon="mdi-city"
								label="Postort"
								hint="Visas upp på kundportalens support-sida"
								type="text"
								required
								counter="20"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Domännamn</v-subheader>

					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="form.hostname"
								:error-messages="errorMessages('hostname')"
								@blur="$v.form.hostname.$touch()"
								prepend-icon="mdi-web"
								label="Domännamn"
								hint="Det domännamn som företagets portal nås via"
								type="text"
								required
								counter="100"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Tjänster</v-subheader>

					<v-row>
						<v-col cols="12">
							<v-select
								v-model="form.services"
								:items="availableServices"
								item-text="name"
								label="Välj tjänster"
								multiple
								chips
								hint="Vilka tjänster erbjuder företaget?"
								persistent-hint
								return-object
								class="px-2"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Ärendetyper</v-subheader>
					<v-row
						v-for="issueCreationType in issueCreationTypes"
						:key="issueCreationType.id"
						justify="center"
						no-gutters
						class="mb-2"
					>
						<v-col sm="4" md="3" lg="2" justify="center">
							{{ issueCreationType.subject }}
						</v-col>
						<v-col sm="3" md="2" lg="1" justify="center">
							<v-icon>mdi-arrow-right</v-icon>
						</v-col>

						<v-col sm="4" md="3" lg="2">
							<v-overflow-btn
								:value="getSelectedIssueType(issueCreationType, company)"
								@change="
									timewaveId =>
										onIssueTypeMapChosen(issueCreationType.id, timewaveId)
								"
								:hideDetails="true"
								:items="issueTypes"
								class="ma-0"
								dense
								flat
								clearable
								label="Intern ärendetyp"
								item-text="name"
								item-value="id"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Ärendeansvarig</v-subheader>
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="form.defaultIssueCreatorId"
								:error-messages="errorMessages('defaultIssueCreatorId')"
								@blur="$v.form.defaultIssueCreatorId.$touch()"
								prepend-icon="mdi-account"
								label="Välj ärendeansvarig"
								type="number"
								required
								hint="Den anställda som anges här kommer stå som skapare av alla ärenden som inkommer via Kundportalen."
								persistent-hint
								counter="10"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Bokningar</v-subheader>
					<v-checkbox
						v-model="form.showBookingEmployees"
						@toggle="$v.form.showBookingEmployees.$touch()"
						label="Visa kopplade anställdas namn"
						type="boolean"
						required
						hint="Om denna ruta är ifylld kommer slutkunder till företaget att kunna se vilka anställda som är kopplade till en bokning i Kundportalen."
						persistent-hint
					/>
				</v-card>
				<v-card class="pa-4 ma-4">
					<v-subheader>Visning i Kundportalen</v-subheader>
					<v-checkbox
						v-model="form.useHourpot"
						@toggle="$v.form.useHourpot.$touch()"
						label="Visa timbankssaldo"
						type="boolean"
						required
						hint="Om denna ruta är ifylld kommer slutkunder till företaget att kunna se sitt tillgängliga timbankssaldo i Kundportalen."
						persistent-hint
					/>
					<v-checkbox
						v-model="form.showNameInHeader"
						@toggle="$v.form.showNameInHeader.$touch()"
						label="Visa företagsnamn i header"
						type="boolean"
						required
						hint="Om denna ruta är ifylld kommer företagets namn att visas i kundportalens header."
						persistent-hint
					/>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Ärenden via mail</v-subheader>
					<v-checkbox
						v-model="form.sendIssuesByEmail"
						@toggle="$v.form.sendIssuesByEmail.$touch()"
						label="Skicka ärenden via mail"
						type="boolean"
						required
						hint="Om denna ruta är ifylld kommer ärenden som inkommer via kundportalen också att skickas till de e-postaddresser som är angivna nedan."
						persistent-hint
					/>
					<v-combobox
						v-model="form.issueEmailAddresses"
						:items="company ? company.issueEmailAddresses : []"
						@blur="$v.form.issueEmailAddresses.$touch()"
						:delimiters="[' ']"
						hide-selected
						multiple
						chips
						deletable-chips
					/>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Färgtema</v-subheader>

					<v-row>
						<v-col cols="6" align="center">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										:color="form.theme.primary"
										@click="toggleColorPicker('primary')"
										v-on="on"
										large
										block
										class="white--text ma-2"
									>
										Välj primärfärg
									</v-btn>
								</template>
								<span>Färgen som syns i meny och större komponenter</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn
										:color="form.theme.secondary"
										@click="toggleColorPicker('secondary')"
										v-on="on"
										large
										block
										class="white--text ma-2"
									>
										Välj sekundärfärg
									</v-btn>
								</template>
								<span>Färg för ikoner, knappar, etc</span>
							</v-tooltip>
							<v-color-picker
								v-model="pickColor"
								flat
								mode="hexa"
								class="pl-4"
								canvas-height="200"
								width="500"
							/>
						</v-col>
						<v-col cols="1" />
						<v-col cols="5">
							<v-card>
								<v-row class="mt-2 px-3">
									<v-toolbar :color="form.theme.primary" dense dark>
										<v-toolbar-title class="toolbar pl-0">
											<v-img
												v-if="iconSmallSrc && !form.removeIconSmall"
												:src="iconSmallSrc"
												class="logo mr-3"
											/>
											<div style="margin: auto">Förhandsvisning</div>
										</v-toolbar-title>
									</v-toolbar>
								</v-row>
								<v-row class="flex-column flex-nowrap align-self-start">
									<v-card :color="form.theme.primary" class="my-3 mx-6" dark>
										<v-card-subtitle>Lorem ipsum</v-card-subtitle>

										<v-card-text class="title pb-0 white--text">
											Lorem ipsum
											<br />
											<div class="subtitle-1">Lorem ipsum dolor sit amet</div>
										</v-card-text>

										<v-list-item>
											<v-list-item-content>
												<v-list-item-title>Lorem ipsum</v-list-item-title>
											</v-list-item-content>
											<v-list-item-icon>
												<v-icon>mdi-arrow-right</v-icon>
											</v-list-item-icon>
										</v-list-item>
									</v-card>
								</v-row>
								<v-row class="flex-column flex-nowrap align-self-start">
									<v-card class="mx-6 my-0">
										<v-list-item two-line>
											<v-list-item-avatar class="mt-auto mb-auto">
												<v-avatar :color="form.theme.secondary">
													<v-icon dark>mdi-email</v-icon>
												</v-avatar>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-title>Lorem ipsum</v-list-item-title>
												<v-list-item-subtitle>
													Lorem ipsum dolor sit amet
												</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-icon class="mt-auto mb-auto">
												<v-icon>mdi-arrow-right</v-icon>
											</v-list-item-icon>
										</v-list-item>
									</v-card>
								</v-row>
								<v-row class="ma-6">
									<v-btn
										:color="form.theme.secondary"
										large
										block
										class="white--text"
									>
										Lorem ipsum
									</v-btn>
									<v-btn :color="form.theme.secondary" large block text>
										Lorem ipsum
									</v-btn>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Logotyp / applikationsikoner</v-subheader>

					<v-row>
						<v-col :md="logoSrc ? 10 : 12" cols="12">
							<v-btn
								:disabled="form.removeLogo"
								@click="pickLogo"
								color="secondary"
								class="ma-2 white--text"
								block
							>
								Ladda upp logotyp (16:9 format eller bredare)
								<v-icon right dark>mdi-cloud-upload</v-icon>
							</v-btn>
							<input
								ref="logo"
								@change="onLogoPicked"
								type="file"
								style="display: none"
								accept="image/*"
							/>
						</v-col>
						<v-col v-if="logoSrc" cols="12" md="2">
							<v-img
								:src="logoSrc"
								:gradient="
									form.removeLogo
										? 'to top right, rgba(225,225,225,.7), rgba(255,255,255,.7)'
										: ''
								"
							/>
							<v-layout class="mt-2" justify-center align-center>
								<v-avatar color="secondary">
									<v-img
										:src="logoSrc"
										:gradient="
											form.removeLogo
												? 'to top right, rgba(225,225,225,.7), rgba(255,255,255,.7)'
												: ''
										"
									/>
								</v-avatar>
							</v-layout>
							<v-checkbox
								v-if="logoSrc"
								v-model="form.removeLogo"
								label="Ta bort"
								class="pl-7 pt-0 mt-0"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col :md="iconSmallSrc ? 10 : 12" cols="12">
							<v-btn
								:disabled="form.removeIconSmall"
								@click="pickIconSmall"
								color="secondary"
								class="ma-2 white--text"
								block
							>
								Ladda upp liten ikon (192x192)
								<v-icon right dark>mdi-cloud-upload</v-icon>
							</v-btn>
							<input
								ref="iconSmall"
								@change="onIconSmallPicked"
								type="file"
								style="display: none"
								accept="image/*"
							/>
						</v-col>
						<v-col v-if="iconSmallSrc" cols="12" md="2">
							<v-img
								:src="iconSmallSrc"
								:gradient="
									form.removeIconSmall
										? 'to top right, rgba(225,225,225,.7), rgba(255,255,255,.7)'
										: ''
								"
							/>
							<v-checkbox
								v-if="iconSmallSrc"
								v-model="form.removeIconSmall"
								label="Ta bort"
								class="pl-7 pt-0 mt-0"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col :md="iconLargeSrc ? 10 : 12" cols="12">
							<v-btn
								:disabled="form.removeIconLarge"
								@click="pickIconLarge"
								color="secondary"
								class="ma-2 white--text"
								block
							>
								Ladda upp stor ikon (512x512)
								<v-icon right dark>mdi-cloud-upload</v-icon>
							</v-btn>
							<input
								ref="iconLarge"
								@change="onIconLargePicked"
								type="file"
								style="display: none"
								accept="image/*"
							/>
						</v-col>
						<v-col v-if="iconLargeSrc" cols="12" md="2">
							<v-img
								:src="iconLargeSrc"
								:gradient="
									form.removeIconLarge
										? 'to top right, rgba(225,225,225,.7), rgba(255,255,255,.7)'
										: ''
								"
							/>
							<v-checkbox
								v-if="iconLargeSrc"
								v-model="form.removeIconLarge"
								label="Ta bort"
								class="pl-7 pt-0 mt-0"
							/>
						</v-col>
					</v-row>
				</v-card>

				<v-card class="pa-4 ma-4">
					<v-subheader>Status i Kundportalen</v-subheader>
					<v-checkbox
						v-model="form.active"
						@toggle="$v.form.active.$touch()"
						label="Aktivt"
						type="boolean"
						required
						hint="Om denna ruta är ifylld så är företagets Kundportal nåbar för slutkunder. Om rutan ej är ifylld meddelas slutkunder att sidan inte hittades om de försöker nå företagets Kundportal."
						persistent-hint
					/>
				</v-card>

				<v-card-actions>
					<v-spacer />
				</v-card-actions>
			</v-form>
		</v-container>
	</v-dialog>
</template>

<script>
import _ from 'lodash';
import {
	required,
	integer,
	minValue,
	maxLength,
	minLength,
	email,
} from 'vuelidate/lib/validators';
import {
	CREATE_COMPANY,
	UPDATE_COMPANY,
	COMPANIES,
	SERVICES,
	ISSUE_CREATION_TYPES,
	ISSUE_TYPES,
	EMPLOYEE,
} from '@/graphql';
import awsMixin from '@/mixins/awsMixin';
export default {
	mixins: [awsMixin],
	props: {
		title: {
			type: String,
			required,
		},
		edit: {
			type: Boolean,
		},
		company: {
			type: Object,
			default: () => {},
		},
	},
	data: () => ({
		dialog: false,
		valid: true,
		loading: false,
		logoSrc: null,
		iconSmallSrc: null,
		iconLargeSrc: null,
		activeColor: 'primary',
		form: {
			id: '',
			secret: '',
			defaultIssueCreatorId: '',
			showBookingEmployees: true,
			useHourpot: false,
			showNameInHeader: true,
			sendIssuesByEmail: false,
			issueEmailAddresses: [],
			active: true,
			name: '',
			orgnr: '',
			email: '',
			tel: '',
			streetAddress: '',
			coCity: '',
			areaCode: '',
			city: '',
			hostname: '',
			cancelRule: '',
			issueTypeMappings: [],
			theme: {
				primary: '#3e71af',
				secondary: '#00467f',
				accent: '#64b5f6',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
				__typename: 'CompanyTheme',
			},
			services: [],
			logo: null,
			logoUrl: '',
			removeLogo: false,
			iconSmall: null,
			iconSmallUrl: '',
			removeIconSmall: false,
			iconLarge: null,
			iconLargeUrl: '',
			removeIconLarge: false,
		},
	}),
	computed: {
		pickColor: {
			get() {
				return this.form.theme[this.activeColor];
			},
			set(newValue) {
				this.form.theme[this.activeColor] = newValue;
			},
		},
		availableServices() {
			return this.services;
		},
	},
	apollo: {
		services: {
			query: SERVICES,
		},
		issueCreationTypes: {
			query: ISSUE_CREATION_TYPES,
		},
		issueTypes: {
			query: ISSUE_TYPES,
			variables() {
				return {
					companyId: this.form.id,
					secret: this.form.secret,
				};
			},
			skip() {
				if (!this.form.id) return true;
				if (!this.edit && !this.form.secret) return true;
				return false;
			},
		},
		employee: {
			query: EMPLOYEE,
			variables() {
				return {
					id: this.form.defaultIssueCreatorId,
					companyId: this.form.id,
					secret: this.form.secret,
				};
			},
			skip() {
				// Skip if no company id or no defaultIssueCreatorId is added
				if (!this.form.defaultIssueCreatorId || !this.form.id) {
					return true;
				}

				// Skip check if new company and defaultIssueCreatorId is empty
				if (!this.edit && !this.form.defaultIssueCreatorId) {
					return true;
				}

				return false;
			},
		},
	},
	watch: {
		'form.areaCode'(value) {
			if (value.length > 3) {
				const areaCode = value.replace(' ', '');
				this.form.areaCode = `${areaCode.slice(0, 3)} ${areaCode.slice(3)}`;
			}
		},
	},
	mounted() {
		if (this.edit) {
			this.dialog = true;
		}

		if (this.company) {
			this.resetForm(this.company);
		}
	},
	validations: {
		form: {
			id: {
				required,
				integer,
				minValue: minValue(0),
			},
			secret: {
				maxLength: maxLength(255),
			},
			defaultIssueCreatorId: {
				required,
				integer,
				minValue: minValue(0),
				maxLength: maxLength(10),
				validEmployeeNumber(value) {
					if (!this.employee) return false;
					if (this.employee.id === value && this.employee.status === 'active') {
						return true;
					}

					return false;
				},
			},
			showBookingEmployees: {
				required,
				// No "bool" validator available in vuelidator
				isBool: v => typeof v === 'boolean',
			},
			useHourpot: {
				required,
				// No "bool" validator available in vuelidator
				isBool: v => typeof v === 'boolean',
			},
			showNameInHeader: {
				required,
				isBool: v => typeof v === 'boolean',
			},
			sendIssuesByEmail: {
				required,
				isBool: v => typeof v === 'boolean',
			},
			issueEmailAddresses: {
				allValid: addrs => addrs.every(email),
			},
			active: {
				required,
				isBool: v => typeof v === 'boolean',
			},
			name: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(100),
			},
			orgnr: {
				required,
				maxLength: maxLength(11),
				validOrganisationNumber(value) {
					return /^\d{6}-\d{4}$/g.test(value);
				},
			},
			hostname: {
				required,
				maxLength: maxLength(100),
				noProtocol(value) {
					return !value.includes('://');
				},
				validDomain(value) {
					return /^[A-Za-z0-9åäöÅÄÖ.-]+(:\d{4,5})?$/g.test(value);
				},
			},
			email: {
				required,
				email,
				maxLength: maxLength(100),
			},
			tel: {
				required,
				maxLength: maxLength(20),
				validPhoneNumber(value) {
					return /^([+]\d{2})?\d{1,3}[\s-]?\d{2,3}\s?\d{2}\s?\d{2}$/g.test(
						value,
					);
				},
			},
			areaCode: {
				required,
				maxLength: maxLength(6),
				validAreaCode(value) {
					return /^\d{3}\s\d{2}$/g.test(value);
				},
			},
			city: {
				required,
				maxLength: maxLength(20),
				validCity(value) {
					return /^[\w\såäöÅÄÖ]{2,20}$/g.test(value);
				},
			},
			streetAddress: {
				maxLength: maxLength(100),
			},
			coCity: {
				maxLength: maxLength(100),
			},
			cancelRule: {
				maxLength: maxLength(400),
			},
		},
	},
	methods: {
		onIssueTypeMapChosen(creationId, timewaveId) {
			const issueTypeMappings = this.form.issueTypeMappings.filter(
				mapping => mapping.creationId !== creationId,
			);
			if (timewaveId) {
				issueTypeMappings.push({ creationId, timewaveId });
			}

			this.form.issueTypeMappings = [...issueTypeMappings];
		},
		toggleColorPicker(type) {
			this.activeColor = type;
		},
		errorMessages(type) {
			const errors = [];
			// Exit if dialog is closed. All errors will blur otherwise.
			if (!this.dialog) {
				return;
			}

			if (!this.$v.form[type].$dirty) {
				return errors;
			}

			if (this.$v.form[type].required === false) {
				errors.push('Det här fältet måste fyllas i');
			}

			if (this.$v.form[type].integer === false) {
				errors.push('Måste vara en siffra');
			}

			if (this.$v.form[type].minValue === false) {
				const value = this.$v.form[type].$params.minValue.min;
				errors.push(`Måste vara minst ${value}`);
			}

			if (this.$v.form[type].maxValue === false) {
				const value = this.$v.form[type].$params.maxValue.max;
				errors.push(`Får inte vara större än ${value}`);
			}

			if (this.$v.form[type].minLength === false) {
				const value = this.$v.form[type].$params.minLength.min;
				errors.push(`Måste vara minst ${value} tecken långt`);
			}

			if (this.$v.form[type].validOrganisationNumber === false) {
				errors.push('Måste vara ett giltigt organisationsnummer');
			}

			if (this.$v.form[type].validPhoneNumber === false) {
				errors.push('Måste vara ett giltigt telefonnummer');
			}

			if (this.$v.form[type].noProtocol === false) {
				errors.push('Får ej innehålla protokoll (t ex https://)');
			}

			if (this.$v.form[type].validDomain === false) {
				errors.push('Måste vara ett giltigt domännamn (FQDN)');
			}

			if (this.$v.form[type].email === false) {
				errors.push('Måste vara en giltig e-postadress');
			}

			if (this.$v.form[type].validAreaCode === false) {
				errors.push('Måste vara en giltig postkod');
			}

			if (this.$v.form[type].validCity === false) {
				errors.push('Måste vara en giltig postort');
			}

			if (this.$v.form[type].validEmployeeNumber === false) {
				errors.push('Måste ange en aktiv anställd');
			}

			return errors;
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (this.$v.$invalid) {
				console.log(this.$v);
				this.$toast.error('Formuläret är inte korrekt ifyllt.');
			} else if (this.edit) {
				this.updateCompany();
			} else {
				this.createCompany();
			}
		},
		resetForm(company) {
			if (company) {
				this.mapCompanyToForm(company);
			} else {
				this.form = {};
				this.form.id = '';
				this.form.secret = '';
				this.form.defaultIssueCreatorId = '';
				this.form.showBookingEmployees = true;
				this.form.useHourpot = false;
				this.form.showNameInHeader = true;
				this.form.sendIssuesByEmail = false;
				this.form.issueEmailAddresses = [];
				this.form.active = true;
				this.form.name = '';
				this.form.email = '';
				this.form.orgnr = '';
				this.form.tel = '';
				this.form.streetAddress = '';
				this.form.areaCode = '';
				this.form.city = '';
				this.form.coCity = '';
				this.form.hostname = '';
				this.form.logoUrl = '';
				this.form.logoType = null;
				this.form.logo = null;
				this.form.removeLogo = false;
				this.form.iconSmallUrl = '';
				this.form.iconSmallType = null;
				this.form.iconSmall = null;
				this.form.removeIconSmall = false;
				this.form.iconLargeUrl = '';
				this.form.iconLargeType = null;
				this.form.iconLarge = null;
				this.form.removeIconLarge = false;
				this.form.issueTypeMappings = [];
				this.form.theme = {
					primary: '#ffffff',
					secondary: '#ffffff',
					accent: '#ffffff',
					info: '#ffffff',
					success: '#ffffff',
					warning: '#ffffff',
					error: '#ffffff',
				};
				this.form.cancelRule = '';
				this.form.services = [];
				this.logoSrc = null;
				this.iconSmallSrc = null;
				this.iconLargeSrc = null;
			}
		},
		mapCompanyToForm(company) {
			this.form.id = company.id;
			this.form.secret = company.secret;
			this.form.defaultIssueCreatorId = company.defaultIssueCreatorId;
			this.form.showBookingEmployees = company.showBookingEmployees;
			this.form.useHourpot = company.useHourpot;
			this.form.showNameInHeader = company.showNameInHeader;
			this.form.sendIssuesByEmail = company.sendIssuesByEmail;
			this.form.issueEmailAddresses = company.issueEmailAddresses;
			this.form.active = company.active;
			this.form.name = company.name;
			this.form.email = company.email;
			this.form.orgnr = company.orgnr;
			this.form.tel = company.tel;
			this.form.streetAddress = company.address.streetAddress;
			this.form.areaCode = company.address.areaCode;
			this.form.city = company.address.city;
			this.form.coCity = company.address.coCity;
			this.form.theme = { ...company.theme };
			this.form.hostname = company.hostname;
			this.form.logoUrl = company.logoUrl;
			this.form.logoType = company.logoType;
			this.form.issueTypeMappings = [...company.issueTypeMappings];
			this.form.logo = null;
			this.form.removeLogo = false;
			this.form.iconSmallUrl = company.iconSmallUrl;
			this.form.iconSmallType = company.iconSmallType;
			this.form.iconSmall = null;
			this.form.removeIconSmall = false;
			this.form.iconLargeUrl = company.iconLargeUrl;
			this.form.iconLargeType = company.iconLargeType;
			this.form.iconLarge = null;
			this.form.removeIconLarge = false;
			this.form.services = company.services;
			this.form.cancelRule = company.cancelRule;
			if (company.logoUrl) {
				this.logoSrc = company.logoUrl;
			}

			if (company.iconSmallUrl) {
				this.iconSmallSrc = company.iconSmallUrl;
			}

			if (company.iconLargeUrl) {
				this.iconLargeSrc = company.iconLargeUrl;
			}
		},
		getCompanyFromForm() {
			const company = {
				id: this.form.id,
				secret: this.form.secret,
				name: this.form.name,
				email: this.form.email,
				orgnr: this.form.orgnr,
				tel: this.form.tel,
				address: {
					streetAddress: this.form.streetAddress,
					areaCode: this.form.areaCode,
					city: this.form.city,
					coCity: this.form.coCity,
				},
				theme: {
					primary: this.form.theme.primary,
					secondary: this.form.theme.secondary,
					accent: this.form.theme.accent,
					info: this.form.theme.info,
					success: this.form.theme.success,
					warning: this.form.theme.warning,
					error: this.form.theme.error,
				},
				issueTypeMappings: this.removeTypeName(this.form.issueTypeMappings),
				services: this.form.services.map(service => service.id),
				logo:
					(this.form.logo && !this.company) ||
					(this.edit && this.form.logoUrl !== this.company.logoUrl),
				logoType: this.form.logoType,
				iconSmall:
					(this.form.iconSmall && !this.company) ||
					(this.edit && this.form.iconSmallUrl !== this.company.iconSmallUrl),
				iconLarge:
					(this.form.iconLarge && !this.company) ||
					(this.edit && this.form.iconLargeUrl !== this.company.iconLargeUrl),
				hostname: this.form.hostname,
				cancelRule: this.form.cancelRule,
				defaultIssueCreatorId: this.form.defaultIssueCreatorId,
				showBookingEmployees: this.form.showBookingEmployees,
				useHourpot: this.form.useHourpot,
				showNameInHeader: this.form.showNameInHeader,
				sendIssuesByEmail: this.form.sendIssuesByEmail,
				issueEmailAddresses: this.form.issueEmailAddresses,
				active: this.form.active,
			};
			if (this.edit) {
				company.removeLogo = this.form.removeLogo;
				company.removeIconSmall = this.form.removeIconSmall;
				company.removeIconLarge = this.form.removeIconLarge;
			}

			return company;
		},
		getOptimisticResponse(mutation, inputType) {
			const company = this.getCompanyFromForm();
			company.__typename = inputType;
			company.address.__typename = 'CompanyAddress';
			company.logoUrl = '';
			company.logoType = null;
			company.preSignedLogoUrl = '';
			company.iconSmallUrl = '';
			company.preSignedIconSmallUrl = '';
			company.iconLargeUrl = '';
			company.preSignedIconLargeUrl = '';
			company.services = [];
			company.theme.__typename = 'CompanyTheme';
			company.issueTypeMappings = company.issueTypeMappings.map(issueMap => ({
				...issueMap,
				__typename: 'IssueTypeMap',
			}));
			const response = {
				__typename: 'Mutation',
			};
			response[mutation] = company;
			return response;
		},
		async createCompany() {
			const optimisticResponse = this.getOptimisticResponse(
				'createCompany',
				'CreateCompanyInput',
			);
			await this.sendRequest(
				CREATE_COMPANY,
				'createCompany',
				'Företaget skapades',
				optimisticResponse,
			);
		},
		async updateCompany() {
			const optimisticResponse = this.getOptimisticResponse(
				'updateCompany',
				'UpdateCompanyInput',
			);
			await this.sendRequest(
				UPDATE_COMPANY,
				'updateCompany',
				'Företaget uppdaterades',
				optimisticResponse,
			);
		},
		async sendRequest(mutation, dataProperty, successText, optimisticResponse) {
			this.loading = true;
			const company = this.getCompanyFromForm();
			this.$apollo
				.mutate({
					mutation,
					variables: { input: company },
					update: async (store, { data: result }) => {
						const companyFromApi = result[dataProperty];
						setTimeout(() => {
							this.$apollo.queries.services.refresh();
						}, 1000);
						if (company.logo) {
							this.$_awsMixin_uploadToS3(
								companyFromApi.preSignedLogoUrl,
								this.form.logo,
							);
							const logoUrl = `${companyFromApi.logoUrl}`;
							setTimeout(() => {
								this.company.logoUrl = logoUrl;
							}, 1000);
							companyFromApi.logoUrl = '';
						}

						if (company.iconSmall) {
							this.$_awsMixin_uploadToS3(
								companyFromApi.preSignedIconSmallUrl,
								this.form.iconSmall,
							);
							const iconSmallUrl = `${companyFromApi.iconSmallUrl}`;
							setTimeout(() => {
								this.company.iconSmallUrl = iconSmallUrl;
							}, 1000);
							companyFromApi.iconSmallUrl = '';
						}

						if (company.iconLarge) {
							this.$_awsMixin_uploadToS3(
								companyFromApi.preSignedIconLargeUrl,
								this.form.iconLarge,
							);
							const iconLargeUrl = `${companyFromApi.iconLargeUrl}`;
							setTimeout(() => {
								this.company.iconLargeUrl = iconLargeUrl;
							}, 1000);
							companyFromApi.iconLargeUrl = '';
						}

						const data = store.readQuery({ query: COMPANIES });
						const companies = data.companies.filter(
							company => company.id !== companyFromApi.id,
						);
						companies.push(companyFromApi);
						data.companies = companies;
						store.writeQuery({ query: COMPANIES, data });
					},
					optimisticResponse,
				})
				.then(() => {
					this.loading = false;
					this.dialog = false;
					this.resetForm();
					this.$toast.success(successText);
				})
				.catch(error => {
					console.error(error);
					this.$toast.error('Kunde inte slutföra förfrågan');
					this.loading = false;
				});
		},
		pickLogo(event) {
			event.preventDefault();
			this.$refs.logo.click();
		},
		pickIconSmall(event) {
			event.preventDefault();
			this.$refs.iconSmall.click();
		},
		pickIconLarge(event) {
			event.preventDefault();
			this.$refs.iconLarge.click();
		},
		async onLogoPicked(event) {
			const { files } = event.target;
			if (files[0]) {
				const logoSource = await this.$_awsMixin_toBase64(files[0]);
				if (await hasInvalidAspectRatio(logoSource)) {
					this.$toast.warning('Logotypen måste ha 16:9 eller bredare format');
				} else {
					this.logoSrc = logoSource;
					this.form.logo = files[0];
					this.form.logoType = files[0].type;
					this.form.logoUrl = files[0].name;
				}
			}
		},
		async onIconSmallPicked(event) {
			const { files } = event.target;
			if (files[0]) {
				const iconSmallSource = await this.$_awsMixin_toBase64(files[0]);
				if (files[0].type !== 'image/png') {
					this.$toast.warning('Ikonen ska vara i PNG-format');
				} else if (await hasInvalidDimensions(iconSmallSource, 192, 192)) {
					this.$toast.warning('Ikonen ska vara 192x192 pixlar stor');
				} else {
					this.iconSmallSrc = iconSmallSource;
					this.form.iconSmall = files[0];
					this.form.iconSmallUrl = files[0].name;
				}
			}
		},
		async onIconLargePicked(event) {
			const { files } = event.target;
			if (files[0]) {
				const iconLargeSource = await this.$_awsMixin_toBase64(files[0]);
				if (files[0].type !== 'image/png') {
					this.$toast.warning('Ikonen ska vara i PNG-format');
				} else if (await hasInvalidDimensions(iconLargeSource, 512, 512)) {
					this.$toast.warning('Ikonen ska vara 512x512 pixlar stor');
				} else {
					this.iconLargeSrc = iconLargeSource;
					this.form.iconLarge = files[0];
					this.form.iconLargeUrl = files[0].name;
				}
			}
		},
		removeTypeName(data) {
			// Removes __typename from as list of objects.
			if (Array.isArray(data)) {
				return data.map(object => _.omit(object, '__typename'));
			}

			return [];
		},
		getSelectedIssueType(issueCreationType, company) {
			if (!company) {
				return null;
			}

			const issueType = _.find(company.issueTypeMappings, {
				creationId: issueCreationType.id,
			});
			if (!issueType) {
				return null;
			}

			return issueType.timewaveId;
		},
	},
};
function hasInvalidAspectRatio(image) {
	const i = new Image();
	const result = new Promise(resolve => {
		i.addEventListener('load', () => {
			const valid = i.width / i.height < 16 / 9;
			resolve(valid);
		});
	});
	i.src = image;
	return result;
}

function hasInvalidDimensions(image, expectedWidth, expectedHeight) {
	const i = new Image();
	const result = new Promise(resolve => {
		i.addEventListener('load', () => {
			const valid = i.width !== expectedWidth || i.height !== expectedHeight;
			resolve(valid);
		});
	});
	i.src = image;
	return result;
}
</script>

<style>
.app-bar {
	border-radius: 0px !important;
}

.toolbar {
	display: flex;
	vertical-align: middle;
}

.toolbar-spacing {
	margin-top: 60px;
}

.logo {
	width: 40px;
	height: 40px;
}
</style>
