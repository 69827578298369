<template>
	<v-card class="mb-1" elevation="9">
		<v-list-item @click="openInfo" three-line>
			<v-list-item-avatar class="mt-auto mb-auto">
				<v-avatar color="secondary">
					<template v-if="company.logoUrl">
						<v-img :src="company.logoUrl" />
					</template>
					<template v-else>
						<v-icon dark>mdi-domain</v-icon>
					</template>
				</v-avatar>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{ company.name }}</v-list-item-title>
				<v-list-item-subtitle>{{ company.orgnr }}</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon class="align-self-center">
				<v-icon>mdi-information-outline</v-icon>
			</v-list-item-icon>
		</v-list-item>
		<template v-if="!company.active">
			<v-card-actions>
				<v-spacer />
				<v-dialog v-model="deleteDialog" @input="finalDeleteActivated = false">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="error">
							Radera
						</v-btn>
					</template>
					<v-card>
						<v-card-title> Radera {{ company.name }}? </v-card-title>

						<v-card-text>
							Detta kommer att ta bort {{ company.name }} och all dess
							relaterade data från Kundportalens databas. Vänligen aktivera
							knappen om du vill fortsätta.
						</v-card-text>

						<v-card-actions>
							<v-switch
								v-model="finalDeleteActivated"
								label="Jag förstår"
								class="mx-4"
							/>
							<v-spacer />
							<v-btn
								@click="deleteCompany()"
								:disabled="!finalDeleteActivated"
								color="error"
							>
								Radera
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-card-actions>
		</template>

		<template v-if="edit">
			<a-company-form-modal :company="company" title="Ändra företag" edit />
		</template>
		<template v-else-if="statistics">
			<a-company-statistics-modal :company="company" statistics />
		</template>
		<template v-else>
			<v-dialog v-model="dialog" width="500">
				<v-card>
					<a-company-info-card :company="company" />
					<v-card-actions class="pb-5 pt-0">
						<v-spacer />
						<v-btn @click="openStats" color="info" class="mr-4">
							Statistik
						</v-btn>
						<v-btn @click="openEdit" color="warning" class="mr-4">Ändra</v-btn>
						<v-btn
							@click="
								edit = false;
								dialog = false;
							"
							color="secondary"
							text
							class="mr-4"
						>
							Stäng
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</v-card>
</template>

<script>
import ACompanyInfoCard from '@/components/CompanyInfoCard';
import ACompanyFormModal from '@/components/CompanyFormModal';
import ACompanyStatisticsModal from '@/components/CompanyStatisticsModal';
import { DELETE_COMPANY, COMPANIES } from '@/graphql';

export default {
	components: {
		ACompanyInfoCard,
		ACompanyFormModal,
		ACompanyStatisticsModal,
	},
	props: {
		company: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		dialog: false,
		edit: false,
		statistics: false,
		deleteDialog: false,
		finalDeleteActivated: false,
	}),
	methods: {
		deleteCompany() {
			const deletedCompanyName = this.company.name;
			const deletedCompanyId = this.company.id;
			this.deleteDialog = false;
			this.$apollo.mutate({
				mutation: DELETE_COMPANY,
				variables: { id: deletedCompanyId },
				update: async (store, { data: result }) => {
					const success = result.deleteCompany;
					if (success) {
						const data = store.readQuery({ query: COMPANIES });
						data.companies = data.companies.filter(
							c => c.id !== deletedCompanyId,
						);
						store.writeQuery({ query: COMPANIES, data });
					}

					this.$toast.success(`${deletedCompanyName} raderades.`);
				},
			});
		},
		openInfo() {
			this.edit = false;
			this.statistics = false;
			this.dialog = true;
		},
		openEdit() {
			this.edit = true;
			this.dialog = false;
		},
		openStats() {
			this.statistics = true;
			this.dialog = false;
		},
	},
};
</script>

<style></style>
