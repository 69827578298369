<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-app-bar dark fixed color="primary" class="app-bar">
			<v-btn @click="dialog = false" icon dark>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-toolbar-title> Statistik för {{ company.name }}</v-toolbar-title>
			<v-spacer />
		</v-app-bar>

		<v-container class="toolbar-spacing white vertical-cover">
			<v-card class="ma-4 pa-4">
				<template v-if="registrationStatistics">
					<v-card-title> Registreringsstatistik </v-card-title>
					<v-card-text>
						Aktiva kunder i TimeWave:
						{{ registrationStatistics.totalActiveClients }}
						<br />
						Kunder med konto i Kundportalen (möjligtvis inaktiva i eller
						borttagna från TimeWave):
						{{ registrationStatistics.registeredClients }}
					</v-card-text>
				</template>
				<template v-else>
					<v-skeleton-loader type="card" />
				</template>
			</v-card>

			<v-card class="ma-4 pa-4">
				<v-card-title> Registrerade kunder </v-card-title>
				<v-card-subtitle>
					Powered by
					<a href="https://sheetjs.com/" class="text-decoration-none">
						SheetJS
					</a>
				</v-card-subtitle>
				<template v-if="fetchUsersClicked">
					<template v-if="users">
						<v-data-table
							:search="search"
							:items-per-page="15"
							:headers="headers"
							:items="users"
							:footer-props="{
								itemsPerPageText: 'Kunder per sida:',
								itemsPerPageOptions: [5, 10, 15],
							}"
							dense
							item-key="clientId"
						>
							<template
								#footer.page-text="{ pageStart, pageStop, itemsLength }"
							>
								{{ pageStart }} - {{ pageStop }} av {{ itemsLength }}
							</template>
							<template #top>
								<v-card-subtitle>
									<v-text-field
										v-model="search"
										label="Sök"
										append-icon="mdi-magnify"
										single-line
									/>
								</v-card-subtitle>
							</template>
						</v-data-table>
						<v-card-text class="text-body-2">
							Dessa kunder kan vara både aktiva och inaktiva i TimeWave.
							Observera att användare som är registrerade i Kundportalen, men
							vars TW-klient-id inte förekommer bland kunderna i svaret från
							TW-API:et (/clients) inte inkluderas här.
						</v-card-text>
						<v-card-actions>
							<v-btn @click="onDownloadClicked()">
								Ladda ned xlsx-fil
							</v-btn>
						</v-card-actions>
					</template>
					<template v-else>
						<v-skeleton-loader type="card" />
					</template>
				</template>
				<template v-else>
					<v-card-actions>
						<v-btn @click="onFetchUsersClicked()">
							Hämta registrerade kunder
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-container>
	</v-dialog>
</template>

<script>
import { writeFile, utils } from 'xlsx';
import { USERS, REGISTRATION_STATISTICS } from '@/graphql';
export default {
	props: {
		statistics: {
			type: Boolean,
			required: false,
		},
		company: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Namn',
				value: 'name',
			},
			{
				text: 'E-postadress',
				value: 'email',
			},
			{
				text: 'Kundnummer',
				value: 'number',
			},
		],
		dialog: false,
		fetchUsersClicked: false,
	}),
	mounted() {
		if (this.statistics) {
			this.dialog = true;
		}
	},
	methods: {
		onDownloadClicked() {
			const { headers } = this;
			const aoa = [
				headers.map(h => h.text),
				...this.users.map(user => headers.map(h => user[h.value])),
			];
			const ws = utils.aoa_to_sheet(aoa);
			const wb = utils.book_new();
			utils.book_append_sheet(wb, ws, 'Användare av Kundportalen');
			writeFile(wb, `${this.company.name}-kundportalen-användare.xlsx`);
		},
		onFetchUsersClicked() {
			this.fetchUsersClicked = true;
		},
	},
	apollo: {
		users: {
			prefetch: false,
			query: USERS,
			variables() {
				return {
					companyId: this.company.id,
				};
			},
			update: data =>
				data.users.map(user => ({
					number: user.clientNumber,
					email: user.email,
					name: user.name,
				})),
			skip() {
				return !this.fetchUsersClicked;
			},
		},
		registrationStatistics: {
			query: REGISTRATION_STATISTICS,
			variables() {
				return {
					companyId: this.company.id,
				};
			},
		},
	},
};
</script>

<style>
.app-bar {
	border-radius: 0px !important;
}

.toolbar {
	display: flex;
	vertical-align: middle;
}

.toolbar-spacing {
	margin-top: 60px;
}

.vertical-cover {
	min-height: 100%;
}
</style>
