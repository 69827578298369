import axios from 'axios';
const awsMixin = {
	methods: {
		$_awsMixin_toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.addEventListener('load', () => resolve(reader.result));
				reader.addEventListener('error', error => reject(error));
			});
		},
		async $_awsMixin_uploadToS3(url, file) {
			if (!url || !file) {
				return;
			}

			return axios.put(url, file, {
				headers: {
					'Content-Type': file.type,
				},
			});
		},
	},
};

export default awsMixin;
