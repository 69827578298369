<template>
	<v-container class="flex-column flex-nowrap align-self-start">
		<v-tabs
			v-model="tab"
			grow
			dark
			background-color="primary"
			show-arrows
			icons-and-text
		>
			<v-tabs-slider color="primary lighten-3" />
			<v-tab href="#tab-companies">
				Företag
				<v-icon>mdi-domain</v-icon>
			</v-tab>
			<v-tab href="#tab-services">
				Tjänster
				<v-icon>mdi-cog-outline</v-icon>
			</v-tab>
		</v-tabs>
		<v-tabs-items v-model="tab">
			<v-tab-item value="tab-companies">
				<a-company-form-modal title="Skapa företag" />
				<div class="mt-3">
					<div v-if="companies">
						<v-card class="pa-4 mx-4 mb-4">
							<v-card-title> Aktiva företag </v-card-title>
							<div v-for="company in activeCompanies" :key="company.id">
								<a-company-info-row :company="company" />
							</div>
						</v-card>
						<v-card class="pa-4 mx-4 mb-4">
							<v-card-title> Inaktiva företag </v-card-title>
							<div v-for="company in inActiveCompanies" :key="company.id">
								<a-company-info-row :company="company" />
							</div>
						</v-card>
					</div>
					<div v-else-if="loadingCompanies">
						<a-loading
							:loading="loadingCompanies"
							type="card-heading, list-item@3"
						/>
						<a-loading
							:loading="loadingCompanies"
							type="card-heading, list-item-two-line, list-item@2"
						/>
					</div>
				</div>
			</v-tab-item>
			<v-tab-item value="tab-services">
				<a-service-form-modal title="Skapa tjänst" />
				<div class="mt-3">
					<div v-if="services" class="px-4 pb-4">
						<div v-for="service in services" :key="service.id">
							<a-service-info-row :service="service" />
						</div>
					</div>
					<div v-else-if="loadingServices">
						<a-loading
							:loading="loadingServices"
							type="card-heading, list-item@3"
						/>
						<a-loading
							:loading="loadingServices"
							type="card-heading, list-item-two-line, list-item@2"
						/>
					</div>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
import ALoading from '@/components/Loading';
import ACompanyFormModal from '@/components/CompanyFormModal';
import ACompanyInfoRow from '@/components/CompanyInfoRow';
import AServiceFormModal from '@/components/ServiceFormModal';
import AServiceInfoRow from '@/components/ServiceInfoRow';
import { COMPANIES, SERVICES } from '@/graphql';

export default {
	name: 'Home',
	components: {
		ALoading,
		ACompanyFormModal,
		ACompanyInfoRow,
		AServiceFormModal,
		AServiceInfoRow,
	},
	data: () => ({
		dialog: false,
		loadingCompanies: true,
		loadingServices: true,
		tab: 'tab-companies',
	}),
	computed: {
		activeCompanies() {
			return this.companies.filter(c => c.active);
		},
		inActiveCompanies() {
			return this.companies.filter(c => !c.active);
		},
	},
	apollo: {
		companies: {
			query: COMPANIES,
			update({ companies }) {
				return companies.sort((a, b) =>
					a.name.localeCompare(b.name, 'sv', { numeric: true }),
				);
			},
		},
		services: {
			query: SERVICES,
			update({ services }) {
				return services.sort((a, b) => (a.name > b.name ? 1 : -1));
			},
		},
	},
};
</script>

<style></style>
