<template>
	<v-container pa-0>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<template v-slot:activator="{ on }" v-if="!edit">
				<div class="d-flex mx-4 mt-4">
					<v-btn
						v-on="on"
						color="secondary"
						class="flex-grow-1 flex-sm-grow-0"
						dark
						>Skapa tjänst</v-btn
					>
				</div>
			</template>
			<v-card>
				<v-app-bar dark fixed color="primary" class="app-bar">
					<v-btn @click="close()" icon dark>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn @click="submit" :disabled="!valid" dark text>Spara</v-btn>
					</v-toolbar-items>
				</v-app-bar>
				<v-form ref="form" v-model="valid">
					<v-card-text
						bg
						fill-height
						grid-list-md
						text-xs-center
						class="mt-11 pt-11"
					>
						<v-card class="pa-6">
							<v-row>
								<v-col cols="12" md="12">
									<v-text-field
										v-model="form.name"
										:error-messages="errorMessages('name')"
										@blur="$v.form.name.$touch()"
										prepend-icon="mdi-tag"
										label="Namn"
										hint="Tjänstens namn"
										type="text"
									/>
								</v-col>

								<v-col :md="imageSrc ? 10 : 12" cols="12">
									<v-btn
										:disabled="form.removeImage"
										@click="pickImage"
										color="secondary"
										class="ma-2 white--text"
										block
									>
										Ladda upp en bild
										<v-icon right dark>mdi-cloud-upload</v-icon>
									</v-btn>
									<input
										ref="image"
										@change="onImagePicked"
										type="file"
										style="display: none"
										accept="image/*"
									/>
								</v-col>
								<v-col v-if="imageSrc" cols="12" md="2">
									<v-img
										:src="imageSrc"
										:gradient="
											form.removeImage
												? 'to top right, rgba(225,225,225,.7), rgba(255,255,255,.7)'
												: ''
										"
									/>
									<v-checkbox
										v-if="imageSrc"
										v-model="form.removeImage"
										label="Ta bort"
										class="pl-7 pt-0 mt-0"
									/>
								</v-col>
							</v-row>
						</v-card>
					</v-card-text>
					<v-card-actions v-if="service">
						<template v-if="service.usedByCount === 0">
							<v-tooltip right>
								<template v-slot:activator="{ on }">
									<v-btn
										@click="deleteService()"
										v-on="on"
										icon
										color="error"
										class="ml-4"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Ta bort {{ service ? service.name : 'tjänst' }}</span>
							</v-tooltip>
						</template>
						<v-spacer />
						<v-btn @click="resetForm(service)" color="error" class="mr-4" text>
							Återställ
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import axios from 'axios';
import { required, minLength } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validationMixin';
import awsMixin from '@/mixins/awsMixin';
import {
	CREATE_SERVICE,
	UPDATE_SERVICE,
	DELETE_SERVICE,
	SERVICES,
} from '@/graphql';

export default {
	mixins: [validationMixin, awsMixin],
	props: {
		title: {
			type: String,
			required,
		},
		edit: {
			type: Boolean,
		},
		service: {
			type: Object,
			default: () => {},
		},
	},
	data: () => ({
		dialog: false,
		valid: true,
		loading: false,
		activeColor: 'primary',
		imageSrc: null,
		form: {
			id: '',
			name: '',
			image: null,
			imageUrl: '',
			removeImage: false,
		},
	}),
	mounted() {
		if (this.edit) {
			this.dialog = true;
		}

		if (this.service) {
			this.resetForm(this.service);
		}
	},
	validations: {
		form: {
			name: {
				required,
				minLength: minLength(3),
			},
		},
	},
	methods: {
		errorMessages(property) {
			return this.$_validationMixin_errorMessages(this.$v.form, property);
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.$toast.error('Formuläret är inte korrekt ifyllt.');
			} else if (this.edit) {
				this.updateService();
			} else {
				this.createService();
			}
		},
		close() {
			this.$v.$reset();
			this.dialog = false;
			this.$emit('update:edit', false);
		},
		resetForm(service) {
			if (service) {
				this.mapServiceToForm(service);
			} else {
				this.form.id = '';
				this.form.name = '';
				this.form.image = null;
				this.form.imageUrl = '';
				this.form.removeImage = false;
				this.imageSrc = null;
			}
		},
		mapServiceToForm(service) {
			this.form.id = service.id;
			this.form.name = service.name;
			this.form.imageUrl = service.imageUrl;
			this.form.image = null;
			this.form.removeImage = false;

			if (service.imageUrl) {
				this.imageSrc = service.imageUrl;
			}
		},
		getServiceFromForm() {
			const service = {
				name: this.form.name,
				image:
					(this.form.image && !this.service) ||
					(this.edit && this.form.imageUrl !== this.service.imageUrl),
			};

			if (this.service && this.service.id) {
				service.id = this.service.id;
			}

			if (this.edit) {
				service.removeImage = this.form.removeImage;
			}

			return service;
		},
		async createService() {
			await this.sendRequest(
				CREATE_SERVICE,
				'createService',
				'Tjänsten skapades',
			);
		},
		async updateService() {
			await this.sendRequest(
				UPDATE_SERVICE,
				'updateService',
				'Tjänsten uppdaterades',
			);
		},
		async deleteService() {
			const agree = await this.$confirm('Är du säker?', {
				title: 'Ta bort tjänst',
				buttonTrueText: 'Ja',
				buttonFalseText: 'Nej',
			});
			if (!agree) {
				return;
			}

			this.loading = true;
			this.$apollo
				.mutate({
					mutation: DELETE_SERVICE,
					variables: { id: this.service.id },
					update: async (store, { data: result }) => {
						const success = result.deleteService;
						if (success) {
							const data = store.readQuery({ query: SERVICES });
							const services = data.services.filter(
								service => service.id !== this.service.id,
							);
							data.services = services;
							store.writeQuery({ query: SERVICES, data });
						}
					},
				})
				.then(() => {
					this.loading = false;
					this.dialog = false;
					this.resetForm();
					this.$toast.success('Tjänsten togs bort');
				})
				.catch(error => {
					console.error(error);
					this.$toast.error('Kunde inte slutföra förfrågan');
					this.loading = false;
				});
		},
		async sendRequest(mutation, dataProperty, successText) {
			this.loading = true;
			const service = this.getServiceFromForm();
			this.$apollo
				.mutate({
					mutation,
					variables: { input: service },
					update: async (store, { data: result }) => {
						const serviceFromApi = result[dataProperty];
						if (service.image) {
							this.$_awsMixin_uploadToS3(
								serviceFromApi.preSignedImageUrl,
								this.form.image,
							);
							const imageUrl = `${serviceFromApi.imageUrl}`;
							setTimeout(() => {
								if (this.service) {
									this.service.imageUrl = imageUrl;
								}
							}, 2000);
							if (this.edit) {
								serviceFromApi.imageUrl = '';
							}
						}

						const data = store.readQuery({ query: SERVICES });
						const services = data.services.filter(
							service => service.id !== serviceFromApi.id,
						);
						services.push(serviceFromApi);
						data.services = services;
						store.writeQuery({ query: SERVICES, data });
					},
				})
				.then(async () => {
					this.loading = false;
					this.resetForm();
					this.$toast.success(successText);
					this.close();
				})
				.catch(error => {
					console.error(error);
					this.$toast.error('Kunde inte slutföra förfrågan');
					this.loading = false;
				});
		},
		async onImagePicked(event) {
			const { files } = event.target;

			if (files[0]) {
				const imageSource = await this.$_awsMixin_toBase64(files[0]);
				if (files[0].type !== 'image/png') {
					this.$toast.warning('Ikonen ska vara i PNG-format');
				} else {
					this.imageSrc = imageSource;
					this.form.image = files[0];
					this.form.imageUrl = files[0].name;
				}
			}
		},
		pickImage(event) {
			event.preventDefault();
			this.$refs.image.click();
		},
	},
};
</script>

<style>
.app-bar {
	border-radius: 0px !important;
}
</style>
