<template>
	<div>
		<v-card-title>
			<span class="headline">{{ company.name }}</span>
		</v-card-title>
		<v-card-subtitle>{{ company.orgnr }}</v-card-subtitle>
		<v-card-text class="mb-4">
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">TimeWave ID</v-col>
				<v-col cols="12" md="8" class="company-content">{{ company.id }}</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Webbadress</v-col>
				<v-col cols="12" md="8" class="company-content">
					<a :href="'https://' + company.hostname">
						{{ company.hostname }}
					</a>
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">E-postadress</v-col>
				<v-col cols="12" md="8" class="company-content">
					<a :href="'mailto:' + company.email">
						{{ company.email }}
					</a>
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Telefonnummer</v-col>
				<v-col cols="12" md="8" class="company-content">
					{{ company.tel }}
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Adress</v-col>
				<v-col cols="12" md="8" class="company-content">
					<template v-if="company.address.coCity.length > 0">
						{{ company.address.coCity }}<br />
					</template>
					{{ company.address.streetAddress }}<br />
					{{ company.address.areaCode }} {{ company.address.city }}
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Logotyp</v-col>
				<v-col cols="12" md="8" class="company-content">
					<a v-if="company.logoUrl" :href="company.logoUrl">
						<v-img
							:src="company.logoUrl"
							alt="icon-small"
							max-height="192"
							contain
						/>
					</a>
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Ikon liten</v-col>
				<v-col cols="12" md="8" class="company-content">
					<a v-if="company.iconSmallUrl" :href="company.iconSmallUrl">
						<v-img
							:src="company.iconSmallUrl"
							alt="icon-small"
							max-height="64"
							contain
						/>
					</a>
				</v-col>
			</v-row>
			<v-row class="company-row">
				<v-col cols="12" md="4" class="company-title">Ikon stor</v-col>
				<v-col cols="12" md="8" class="company-content">
					<a v-if="company.iconLargeUrl" :href="company.iconLargeUrl">
						<v-img
							:src="company.iconLargeUrl"
							alt="icon-large"
							max-height="128"
							contain
						/>
					</a>
				</v-col>
			</v-row>
		</v-card-text>
	</div>
</template>

<script>
export default {
	props: {
		company: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		dialog: false,
	}),
};
</script>

<style>
.company-row {
	padding-top: 1px;
	margin-top: 1px;
	padding-bottom: 1px;
	margin-bottom: 1px;
}
.company-title {
	font-size: 16px;
	font-weight: 500;
	padding-top: 1px;
	margin-top: 1px;
	padding-bottom: 1px;
	margin-bottom: 1px;
}
.company-content {
	font-size: 14px;
	color: #333333;
	padding-top: 1px;
	margin-top: 1px;
	padding-bottom: 1px;
	margin-bottom: 1px;
}
</style>
