const validationMixin = {
	methods: {
		$_validationMixin_errorMessages(context, type) {
			const errors = [];
			if (!context[type].$dirty) {
				return errors;
			}

			if (context[type].required === false) {
				errors.push('Det här fältet måste fyllas i');
			}

			if (context[type].integer === false) {
				errors.push('Måste vara en siffra');
			}

			if (context[type].minValue === false) {
				const value = context[type].$params.minValue.min;
				errors.push(`Måste vara minst ${value}`);
			}

			if (context[type].maxValue === false) {
				const value = context[type].$params.maxValue.max;
				errors.push(`Får inte vara större än ${value}`);
			}

			if (context[type].minLength === false) {
				const value = context[type].$params.minLength.min;
				errors.push(`Måste vara minst ${value} tecken långt`);
			}

			if (context[type].validOrganisationNumber === false) {
				errors.push('Måste vara ett giltigt organisationsnummer');
			}

			if (context[type].validPhoneNumber === false) {
				errors.push('Måste vara ett giltigt telefonnummer');
			}

			if (context[type].noProtocol === false) {
				errors.push('Får ej innehålla protokoll (t ex https://)');
			}

			if (context[type].validDomain === false) {
				errors.push('Måste vara ett giltigt domännamn (FQDN)');
			}

			if (context[type].email === false) {
				errors.push('Måste vara en giltig e-postadress');
			}

			if (context[type].validAreaCode === false) {
				errors.push('Måste vara en giltig postkod');
			}

			if (context[type].validCity === false) {
				errors.push('Måste vara en giltig postort');
			}

			return errors;
		},
	},
};

export default validationMixin;
